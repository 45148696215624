import React, {useEffect, useState} from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Gallery, Item } from "react-photoswipe-gallery";
import YouTube from "react-youtube";

const tabList = ["All", "Church", "Sports", "Other"];
const AllPortfolioContent = [
  {
    img: "img/portfolio/molly.jpg",
    title: "Video Testimony",
    subTitle: "Molly Kruger",
    category: "Church",
    videoId: "ibsbt22Y4YI",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/meetify-intro-2025.jpg",
    title: "Site Promo",
    subTitle: "Meetify",
    category: "Other",
    videoId: "NnSpslNNkaY",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/ranika.jpg",
    title: "Speaker Reel",
    subTitle: "Ranika Chaney",
    category: "Other",
    videoId: "X6xkogJG57M",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/calendar-conspiracy.jpg",
    title: "Movie Trailer",
    subTitle: "Meetify marketing",
    category: "Other",
    videoId: "NUcMHFVPq0Y",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/chill.jpg",
    title: "it's chill",
    subTitle: "Instagram music video",
    category: "Other",
    videoId: "5PLkGBPAVuA",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/water-cooler1.jpg",
    title: "Water Cooler Comedy",
    subTitle: "Meetify marketing",
    category: "Other",
    videoId: "YJnmof_r2xg",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/brad-spinks.jpg",
    title: "Video Testimony",
    subTitle: "Brad Spinks",
    category: "Church",
    videoId: "_7HFWf2ZPqI",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/wrestling2.jpg",
    title: "Season Highlights",
    subTitle: "CCS Wrestling 2020",
    category: "Sports",
    videoId: "ACQpe_2t8Mw",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/marcus-and-tiara.jpg",
    title: "Video Testimony",
    subTitle: "Marcus & Tiara Perry",
    category: "Church",
    videoId: "I6mcW3eGLss",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/emma.jpg",
    title: "Mission Trip Video",
    subTitle: "Emma Buresh",
    category: "Church",
    videoId: "BtfvKj2eJ0s",
    aspect: "wide",
    portfolioLink:
      "",
  },

  {
    img: "img/portfolio/henry2.jpg",
    title: "Game Highlights",
    subTitle: "Henry Rutledge",
    category: "Sports",
    videoId: "0ACDjOJ42p4",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/trina-mays.jpg",
    title: "Video Testimony",
    subTitle: "Trina Mays",
    category: "Church",
    videoId: "r2FACxn-9PA",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/erin-phelps.jpg",
    title: "Video Testimony",
    subTitle: "Erin Phelps",
    category: "Church",
    videoId: "JivJe_9mex0",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/jailmom1.jpg",
    title: "Jail Mom",
    subTitle: "SignUpGenius promo",
    category: "Other",
    videoId: "l6L_-MxA0CQ",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/OE-video.jpg",
    title: "Explainer Video",
    subTitle: "Outreach & Engagement",
    category: "Church",
    videoId: "XJOLv5-2GHI",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/boys-are-back.jpg",
    title: "Season Hype Video",
    subTitle: "CCS Wrestling 2020",
    category: "Sports",
    videoId: "HtT3O7D4xsc",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/henry-hype.jpg",
    title: "Instagram Hype Video",
    subTitle: "Henry Rutledge",
    category: "Sports",
    videoId: "UffagCNjGZg",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/dave-grigg.jpg",
    title: "Video Testimony",
    subTitle: "Dave Grigg",
    category: "Church",
    videoId: "R-q1ur65dd8",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/timika.jpg",
    title: "Video Testimony",
    subTitle: "Timika Ingram",
    category: "Church",
    videoId: "G3K9mB2QCEs",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/upward1.jpg",
    title: "Virtue TV: 125",
    subTitle: "Upward Sports",
    category: "Other",
    videoId: "mbwrE4AjLUU",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/tyson.jpg",
    title: "Mission Trip Video",
    subTitle: "Tyson Presnell",
    category: "Church",
    videoId: "_y_EBDY9o-8",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/dale.jpg",
    title: "Video Testimony",
    subTitle: "Dale Austin",
    category: "Church",
    videoId: "Tn810UX572k",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/wrestling3.jpg",
    title: "Season Highlights",
    subTitle: "CCS Wrestling 2021",
    category: "Sports",
    videoId: "DT0Qn1poRJA",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/kishan-patel.jpg",
    title: "Video Testimony",
    subTitle: "Kishan Patel",
    category: "Church",
    videoId: "q-8uhh3LRwA&t",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/upward2.jpg",
    title: "Virtue TV: 103",
    subTitle: "Upward Sports",
    category: "Other",
    videoId: "jdwXvA2SeBY",
    aspect: "wide",
    portfolioLink:
      "",
  },
  {
    img: "img/portfolio/justin.jpg",
    title: "Video Testimony",
    subTitle: "Justin Jordan",
    category: "Church",
    videoId: "5nPCNlZ_6So",
    aspect: "wide",
    portfolioLink:
      "",
  },  
  {
    img: "img/portfolio/henry1.jpg",
    title: "Game Highlights",
    subTitle: "Henry Rutledge",
    category: "Sports",
    videoId: "yP2giTuJXeQ",
    aspect: "wide",
    portfolioLink:
      "",
  },


  
];

/*
  {
    img: "img/portfolio/wrestling1.jpg",
    title: "2019 Hype Video",
    subTitle: "CCS Wrestling",
    category: "Sports",
    videoId: "HtT3O7D4xsc",
    aspect: "wide",
    portfolioLink:
      "",
  },
    {
    img: "img/portfolio/henry1.jpg",
    title: "Game Highlights",
    subTitle: "Henry Rutledge Recruiting",
    category: "Sports",
    videoId: "yP2giTuJXeQ",
    aspect: "wide",
    portfolioLink:
      "",
  },

  */


const Portfolio = () => {

  const [vheight, setVheight] = useState(506);
  const [vwidth, setVwidth] = useState(900);
  const [categ, setCateg] = useState("All");

  const setVideoDimensions = () => {
    let vwidth = "900";
    let vheight = "506";
    if (window.innerWidth <= 400) {
      vwidth = "290";
      vheight = "163";
    } else if (window.innerWidth > 400 && window.innerWidth < 640) {
      vwidth = "400";
      vheight = "225";
    } else if (window.innerWidth >= 640 && window.innerWidth < 900) {
      vwidth = "640";
      vheight = "360";
    }
    setVheight(vheight);
    setVwidth(vwidth);
    return {width: vwidth, height: vheight}
  }

  window.addEventListener("resize", setVideoDimensions);

  useEffect(() => {
    setVideoDimensions();
  },[])


  return (
    <div className="positon-relative">
      <div className="portfolio-filter-01">
        
          <TabList className="filter d-flex portfolio-link justify-content-center">
            {tabList.map((val, i) => (
              <Tab key={i} onClick={()=>setCateg(val)}>{val}</Tab>
            ))}
          </TabList>
          {/* End tablist */}
        
          <Gallery>
            <div>
              <div className="portfolio-content row lightbox-gallery">
                {AllPortfolioContent.filter(x=> categ === 'All' || categ === x.category).map((val, i) => (
                  <div className="col-sm-6 col-lg-4 grid-item product" key={i}>
                    <div className="portfolio-box-01">
                      <div className="portfolio-img">
                        <div className="portfolio-info">
                          <h5>
                            <a
                              href={val.portfolioLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {val.title}
                            </a>
                          </h5>
                          <span>{val.subTitle}</span>
                        </div>
                        {/* End .portfolio-info */}
                        <Item
                          original={val.img}
                          thumbnail={val.img}
                          width={vwidth}
                          height={vheight}
                          content={
                            <div className="video-responsive" align="center">
                              <YouTube videoId={val.videoId} opts={{height: vheight, width: vwidth, playerVars: {modestbranding: true, rel: 0, hd: 1, vq: 'hd720', showinfo: 0, autohide: 1}}} />
                            </div>
                          }
                        >
                          
                          {({ ref, open }) => (
                            <img
                              src={val.img}
                              alt="Shoot"
                              role="button"
                              ref={ref}
                              onClick={open}
                            />
                          )}
                        </Item>

                        {/* <a
                          href={val.portfolioLink}
                          className="portfolio-icon"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="ti-link"></span>
                        </a> */}
                        {/* End .portfolio-link */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* End list wrapper */}
            </div>
            {/* End tabpanel */}
        </Gallery>
          
          
      </div>
    </div>
  );
};

export default Portfolio;
